import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Axios from "axios";
import {
  Paper,
  Typography,
  Grid,
  ListItem,
  ListItemText,
  List,
} from "@material-ui/core";
import "../Admin.css";
import InfluencerOutreachEmail from "../InfluencerOutreachEmail";
import InfluencerSettings from "./InfluencerSettings";
import InfluencerAcceptedContractEmail from "../InfluencerAcceptedContractEmail";
import InfluencerContentApprovedEmail from "../InfluencerContentApprovedEmail";
import InfluencerContentReminderEmail from "../InfluencerContentReminderEmail";
import InfluencerContractReminderEmail from "../InfluencerContractReminderEmail";
import InfluencerInsightsReminderEmail from "../InfluencerInsightsReminderEmail";
import InfluencerAdminTemplate from "./InfluencerAdminTemplate";
import HtmlEmailTemplateEditor from "../HtmlEmailTemplateEditor";

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
}));

const EmailTemplatesAdmin = () => {
  const classes = useStyles();
  const [menu, setMenu] = useState("");
  const list = [
    "Purchase Order Email",
    "Outreach Email",
    "Accepted Contract Email",
    "Approved Content Email",
    "Content Reminder Email",
    "Insights Upload Reminder Email",
    "Contract Reminder Email",
    "Master Influencer Agreement",
    "Statement of Work",
    "Default Settings",
  ];

  return (
    <main className={classes.content}>
      <Grid container spacing={2}>
        <Grid item xs={3} md={3} lg={3}>
          <div className="main-content">
            <Typography variant="h5">Influencer & Purchase Order</Typography>
            <Paper className="contract-releases-list">
              <List component="nav">
                {list.map((item) => {
                  return (
                    <ListItem
                      className={menu === item ? "selected-menu" : ""}
                      button
                      key={item}
                      onClick={() => setMenu(item)}
                    >
                      <ListItemText primary={item} />
                    </ListItem>
                  );
                })}
              </List>
            </Paper>
          </div>
        </Grid>
        <Grid item xs={9} md={9} lg={9}>
          {menu === "Outreach Email" ? (
            <InfluencerOutreachEmail />
          ) : menu === "Default Settings" ? (
            <InfluencerSettings />
          ) : menu === "Accepted Contract Email" ? (
            <InfluencerAcceptedContractEmail />
          ) : menu === "Approved Content Email" ? (
            <InfluencerContentApprovedEmail />
          ) : menu === "Content Reminder Email" ? (
            <InfluencerContentReminderEmail />
          ) : menu === "Contract Reminder Email" ? (
            <InfluencerContractReminderEmail />
          ) : menu === "Insights Upload Reminder Email" ? (
            <InfluencerInsightsReminderEmail />
          ) : menu === "Master Influencer Agreement" ? (
            <InfluencerAdminTemplate title="Master Influencer Agreement" docFileId="1cvtWYiFkGnt1aqP6yO-Zm3bN_oH7j86qVZCmt6OSaD4"/>
          ) : menu === "Statement of Work" ? (
            <InfluencerAdminTemplate title="Statement of Work" docFileId="10hG8MVmP-s8Yx7O4zy0t70oZk7U_8zqJB2QLZbR1O8Y"/>
          ) : menu === "Purchase Order Email" ? (
            <HtmlEmailTemplateEditor title="Purchase Order Email Template" templateCategory="PurchaseOrderEmail"/>
          ) : (
            <React.Fragment></React.Fragment>
          )}
        </Grid>
      </Grid>
    </main>
  );
};

export default EmailTemplatesAdmin;
 