import { Button, Typography } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import RadioIcon from "@material-ui/icons/Radio";
import ReceiptIcon from "@material-ui/icons/Receipt";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import moment from "moment";
import PayableFileViewer from "./PayableFileViewer";
import PayableMediaForm from "./PayableMediaForm";
import axios from "axios";
import ConfirmationDialogActions from "../../Shared/ConfirmationDialogActions";
import { currencyFormat } from "../../../utils/GeneralUtils";
import { useParams } from "react-router-dom";
import ConfirmationDialogWithInput from "../../Shared/ConfirmationDialogWithInput";
import ReceiptForm from "./Receipt/ReceiptForm";
import ApInvoiceForm from "./ApInvoices/ApInvoiceForm";
import SplitPayableDialog from "./SplitBill/SplitPayableDialog";
import SendPayableToXeroDialog from "./SendPayableToXeroDialog";
import { useAuth } from "../../../context/auth/AuthContext";

const PayableRow = ({
  payable,
  mediaSuppliers,
  companies,
  setSnackBarProps,
  searchRelatedMediaSpend,
  resetRelatedRecords,
  setSelectedPayable,
  handleUpdatePayablesList,
  setSelectedReconLines,
  removePayablefromList,
  applyConversionPayablesList,
  setIsClosingPayable,
  setIsLoadingPayables,
  selectedPayable,
  staff,
  projects,
  searchRelatedPurchaseOrder,
  resetPayables,
  selectedReconLines,
}) => {
  const { user } = useAuth();
  const { id, type } = useParams();
  const [localPayable, setLocalPayable] = useState(payable);
  const [isExpanded, setIsExpanded] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const [loadingPdf, setLoadingPdf] = useState(true);
  const [openRejectWarning, setOpenRejectWarning] = useState(false);
  const [openRejectReceiptWarning, setOpenRejectReceiptWarning] =
    useState(false);
  const [openNotifyMediaTeam, setOpenNotifyMediaTeam] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [openSendBillToXeroDialog, setOpenSendBillToXeroDialog] =
    useState(false);
  const [openSplitPayableDialog, setOpenSplitPayableDialog] = useState(false);
  const [openConvertDialog, setOpenConvertDialog] = useState(false);
  const [convertToType, setConvertToType] = useState(null);
  const [isConverting, setIsConverting] = useState(false);
  const elementRef = useRef(null);

  useEffect(() => {
    if (
      payable &&
      id &&
      type &&
      payable.recordId == id &&
      payable.recordType === type
    ) {
      //searchRelatedMediaSpend(payable);
      setSelectedPayable(payable);
      setSelectedReconLines([]);
      scrollToView();
    }
  }, [id, type, payable]);

  useEffect(() => {
    if (isExpanded) {
      scrollToView();
    }
  }, [isExpanded]);

  useEffect(() => {
    setIsExpanded(selectedPayable?.recordId === payable?.recordId);
  }, [selectedPayable]);

  useEffect(() => {
    setLocalPayable(payable);
  }, [payable]);

  const scrollToView = () => {
    window.scrollTo({
      top: elementRef?.current?.offsetTop,
      behavior: "smooth",
    });
  };

  const renderRecordTypeIcon = () => {
    return localPayable.recordType === "MEDIA" ||
      localPayable.recordType === "UNRECOGNISED_MEDIA" ? (
      <RadioIcon />
    ) : localPayable.recordType === "RECEIPT" ? (
      <ReceiptIcon />
    ) : (
      <InsertDriveFileIcon />
    );
  };

  const handleExpandPayable = (e) => {
    e.preventDefault();
    // setIsExpanded(!isExpanded);
    if (!isExpanded) {
      //searchRelatedMediaSpend(localPayable);
      setSelectedPayable(localPayable);
      setSelectedReconLines([]);
      if (localPayable.recordType === "PO")
        searchRelatedPurchaseOrder(localPayable);
      if (localPayable.recordType === "MEDIA")
        searchRelatedMediaSpend(localPayable);
    } else {
      resetRelatedRecords();
      setSelectedPayable(null);
    }
  };

  const handleUpdateLocalPayable = (field, value) => {
    if (field === "mediaSupplierId") {
      setLocalPayable({
        ...localPayable,
        [field]: value.id,
        mediaSupplierName: value.supplierName,
      });
    } else if (field === "companyId") {
      setLocalPayable({
        ...localPayable,
        [field]: value?.id || null,
        companyName: value?.name || null,
      });
    } else if (field === "personId") {
      setLocalPayable({
        ...localPayable,
        [field]: value?.id ? value.id : null,
        userId: null,
      });
    } else if (field === "userId") {
      setLocalPayable({
        ...localPayable,
        [field]: value?.id ? value.id : null,
        personId: null,
      });
    } else if (field === "xeroContactId") {
      setLocalPayable({
        ...localPayable,
        xeroContactName: value?.Name || null,
        xeroContactId: value?.ContactID || null,
      });
    } else {
      setLocalPayable({ ...localPayable, [field]: value });
    }
    setHasChanges(true);
  };

  const handleUpdateMultiplePropertiesLocalPayable = (updates) => {
    let newPayable = { ...localPayable };
    updates.forEach(({ field, value }) => {
      if (field === "mediaSupplierId") {
        newPayable = {
          ...newPayable,
          [field]: value.id,
          mediaSupplierName: value.supplierName,
        };
      } else if (field === "companyId") {
        newPayable = {
          ...newPayable,
          [field]: value?.id ? value.id : null,
          companyName: value?.name ? value.name : null,
        };
      } else {
        newPayable = { ...newPayable, [field]: value };
      }
    });

    setLocalPayable(newPayable);
    setHasChanges(true);
  };

  const displayRejectWarning = () => {
    if (localPayable.recordType == "RECEIPT") {
      setOpenRejectReceiptWarning(true);
    } else {
      setOpenRejectWarning(true);
    }
  };

  const handleOpenNotifyMediaTeam = () => {
    setOpenNotifyMediaTeam(true);
  };

  const handleOpenSendBillToXero = () => {
    setOpenSendBillToXeroDialog(true);
  };

  const handleRejectPayable = (rejectionReason = null) => {
    setIsClosingPayable(true);
    setIsLoadingPayables(true);
    var body = {
      rejectionReason: rejectionReason,
    };
    axios
      .post(
        `Reconciliation/RejectPayable/${localPayable.recordType}/${localPayable.recordId}`,
        body
      )
      .then(({ data }) => {
        setHasChanges(false);
        setSnackBarProps({
          open: true,
          severity: "success",
          text: "Done! The bill has been rejected.",
        });
        removePayablefromList(localPayable);
        resetRelatedRecords();
        setSelectedPayable(null);
      })
      .catch((err) => {
        console.log(err);
        setSnackBarProps({
          open: true,
          severity: "warning",
          text: "Unable to reject.",
        });
      })
      .finally(() => {
        setIsClosingPayable(false);
        setIsLoadingPayables(false);
      });
  };

  const handleApproveForPayment = () => {
    setIsClosingPayable(true);
    setIsLoadingPayables(true);
    axios
      .post(
        `Reconciliation/ApproveForPayment/${localPayable.recordType}/${localPayable.recordId}`
      )
      .then(({ data }) => {
        if (
          localPayable.recordType === "MEDIA" &&
          data?.status !== "Submitted"
        ) {
          setSnackBarProps({
            open: true,
            severity: "warning",
            text: "Please make sure you've selected and saved the media lines before approving for payment.",
          });
          return;
        }
        setHasChanges(true);
        setSnackBarProps({
          open: true,
          severity: "success",
          text: "Success!",
        });
        handleUpdatePayablesList(data);
        resetRelatedRecords();
        setSelectedPayable(data);
        setHasChanges(false);
      })
      .catch((err) => {
        console.log(err);
        setSnackBarProps({
          open: true,
          severity: "warning",
          text: "Error when updating record.",
        });
      })
      .finally(() => {
        setIsClosingPayable(false);
        setIsLoadingPayables(false);
      });
  };

  const handleSendBillToXero = () => {
    setIsLoading(true);
    axios
      .post(`/accounting/SendMediaBillToXero/${localPayable.recordId}`, null, {
        params: {
          xeroContactId: localPayable.xeroContactId,
        },
      })
      .then(({ data }) => {
        setHasChanges(false);
        setSnackBarProps({
          open: true,
          severity: "success",
          text: "Done! The bill has been sent to Xero.",
        });
        removePayablefromList(localPayable);
        resetRelatedRecords();
        setSelectedPayable(null);
        setOpenSendBillToXeroDialog(false);
      })
      .catch((err) => {
        console.log(err);
        setSnackBarProps({
          open: true,
          severity: "warning",
          text: "Unable to send to Xero.",
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleSavePayable = () => {
    axios
      .post(
        `Reconciliation/SavePayableForReconciliation/${localPayable.recordType}`,
        localPayable
      )
      .then(({ data }) => {
        setHasChanges(false);
        setSnackBarProps({
          open: true,
          severity: "success",
          text: "Great! The record has been saved.",
        });
        if (localPayable.recordType === "UNRECOGNISED_MEDIA") {
          applyConversionPayablesList(localPayable, data);
        } else {
          handleUpdatePayablesList(data);
        }
        setSelectedPayable(data);
        setHasChanges(false);
      })
      .catch((err) => {
        console.log(err);
        setSnackBarProps({
          open: true,
          severity: "warning",
          text: "Unable to update.",
        });
      })
      .finally(() => {});
  };

  const goToClientPage = (e) => {
    e.preventDefault();
    e.stopPropagation();
    window.open(`/Client/Home/${localPayable.companyId}/2`, "_blank");
  };

  const handleNotifyMediaTeam = (note) => {
    setIsLoading(true);
    axios
      .post(
        `Reconciliation/NotifyMediaTeam/${localPayable.recordType}/${localPayable.recordId}`,
        {
          note: note,
        },
        {
          params: {
            userEmail: user?.email,
          },
        }
      )
      .then((res) => {
        setOpenNotifyMediaTeam(false);
        setSnackBarProps({
          open: true,
          severity: "success",
          text: "Great! We have emailed the media team.",
        });
      })
      .catch((err) => {
        console.log(err);
        setSnackBarProps({
          open: true,
          severity: "warning",
          text: "Unable to send email.",
        });
      })
      .finally(() => setIsLoading(false));
  };

  const renderRecordTypeColours = () => {
    if (
      payable.recordType === "MEDIA" ||
      payable.recordType === "UNRECOGNISED_MEDIA"
    ) {
      if (payable.status == "Submitted") {
        return "reconciliation-centre-payable-row-media-approved-by-media-team";
      }
      const supplierColour =
        mediaSuppliers.find((s) => s.id === payable.mediaSupplierId)?.colour ||
        "#42C2EB";
      switch (supplierColour.toUpperCase()) {
        case "#4259EB":
          return "reconciliation-centre-payable-row-media-1-type";
        case "#AB42EB":
          return "reconciliation-centre-payable-row-media-2-type";
        case "#42C2EB":
          return "reconciliation-centre-payable-row-media-3-type";
        case "#EBBC42":
          return "reconciliation-centre-payable-row-media-4-type";

        default:
          return "reconciliation-centre-payable-row-media-3-type";
      }
    }

    if (payable.recordType === "RECEIPT" && payable.status == "Submitted") {
      return "reconciliation-centre-payable-row-receipt-approved-for-payment";
    }
    return `reconciliation-centre-payable-row-${payable.recordType}-type`;
  };

  const handleConvertPayable = (recordId, fromType, toType) => {
    setIsConverting(true);
    axios
      .post(`Reconciliation/ConvertPayable/${recordId}/${fromType}/${toType}`)
      .then(({ data }) => {
        setHasChanges(false);
        setSnackBarProps({
          open: true,
          severity: "success",
          text: "Great News! The bill has been converted.",
        });
        applyConversionPayablesList(localPayable, data);
        resetRelatedRecords();
        setSelectedPayable(data);
        setOpenConvertDialog(false);
        setConvertToType(null);
      })
      .catch((err) => {
        console.log(err);
        setSnackBarProps({
          open: true,
          severity: "warning",
          text: "Unable to convert payable.",
        });
      })
      .finally(() => {
        setIsConverting(false);
      });
  }


  return (
    <div ref={elementRef}>
      <div
        className={`reconciliation-centre-payable-row-root ${renderRecordTypeColours()} `}
      >
        {openSplitPayableDialog && (
          <SplitPayableDialog
            open={openSplitPayableDialog}
            handleClose={() => setOpenSplitPayableDialog(false)}
            payable={localPayable}
            companies={companies}
            handleSuccess={() => {
              setOpenSplitPayableDialog(false);
              setSnackBarProps({
                open: true,
                severity: "success",
                text: "Successfully split bill",
              });
              resetPayables();
            }}
            handleError={() =>
              setSnackBarProps({
                open: true,
                severity: "warning",
                text: "Unable to split bill. Please try again.",
              })
            }
          />
        )}
        {openRejectReceiptWarning && (
          <ConfirmationDialogWithInput
            open={openRejectReceiptWarning}
            handleClose={() => {
              setOpenRejectReceiptWarning(false);
            }}
            handleSubmit={handleRejectPayable}
            submitLabel={"Reject"}
            description={
              "Please enter the reason. This will be seen by the staff member who applied for the reimbursment in the Pay app."
            }
            textFieldLabel={"Reason"}
            title={"Reject Receipt"}
            loading={isLoading}
          />
        )}

        {openRejectWarning && (
          <ConfirmationDialogActions
            title={"Are you sure?"}
            open={openRejectWarning}
            contentText={"This action will delete the bill from the list."}
            button1Action={() => {
              setOpenRejectWarning(false);
            }}
            button1Text={"Cancel"}
            button2Text={"Delete"}
            button1Class="default-button-grey"
            button2Class="default-button"
            button2Action={() => handleRejectPayable()}
          />
        )}

        {openConvertDialog && (
          <ConfirmationDialogActions
            title={"Are you sure?"}
            open={openConvertDialog}
            contentText={
              `This action will convert the bill to ${convertToType}.`
            }
            button1Action={() => {
              setConvertToType(null)
              setOpenConvertDialog(false);
            }}
            button1Text={"Cancel"}
            button2Text={"Confirm"}
            button1Class="default-button-grey"
            button2Class="default-button"
            button2Action={() =>
              handleConvertPayable(payable.recordId, payable.recordType, convertToType)
            }
            isLoading={isConverting}
          />
        )}

        {/* {openSendBillToXeroDialog && (
          <ConfirmationDialogActions
            title={"Are you sure?"}
            open={openSendBillToXeroDialog}
            contentText={"This step will authorise the bill for payment."}
            button1Action={() => {
              setOpenSendBillToXeroDialog(false);
            }}
            button1Text={"Cancel"}
            button2Text={"Send"}
            button1Class="default-button-grey"
            button2Class="default-button"
            button2Action={handleSendBillToXero}
            isLoading={isLoading}
          />
        )} */}
        {openSendBillToXeroDialog && (
          <SendPayableToXeroDialog
            open={openSendBillToXeroDialog}
            handleClose={() => {
              setOpenSendBillToXeroDialog(false);
            }}
            handleSubmit={handleSendBillToXero}
            payable={payable}
            isLoading={isLoading}
            handleUpdateLocalPayable={handleUpdateLocalPayable}
          />
        )}

        {openNotifyMediaTeam && (
          <ConfirmationDialogWithInput
            open={openNotifyMediaTeam}
            handleClose={() => {
              setOpenNotifyMediaTeam(false);
            }}
            handleSubmit={handleNotifyMediaTeam}
            submitLabel={"Notify"}
            description={
              "We'll send an email to Nick Fleming to check the bill."
            }
            textFieldLabel={"Comment"}
            title={"Are you sure?"}
            loading={isLoading}
          />
        )}
        <div
          className="reconciliation-centre-payable-row-header"
          onClick={handleExpandPayable}
        >
          <div className="reconciliation-centre-payable-row-container">
            <div
              className={`reconciliation-payable-row-record-type-container reconciliation-payable-row-record-type-${payable.recordType}`}
            >
              {renderRecordTypeIcon()}
              <Typography variant="caption">
                {localPayable.recordType === "UNRECOGNISED_MEDIA"
                  ? "MEDIA"
                  : localPayable.recordType}
              </Typography>
            </div>
            <div className="reconciliation-payable-row-record-name-container">
              <Typography variant="caption">
                {localPayable.companyName && (
                  <a
                    onClick={(e) => goToClientPage(e)}
                    target="_blank"
                    href={`/Client/Home/${localPayable.companyId}/2`}
                  >
                    {localPayable.companyName}
                  </a>
                )}
              </Typography>
              <Typography variant="caption">
                {localPayable.recordType !== "UNRECOGNISED_MEDIA"
                  ? localPayable.name
                  : "Unrecognised Bill - Action Needed"}
              </Typography>
              {localPayable.date && (
                <Typography variant="caption">
                  Date: {moment(localPayable.date)?.format("DD/MM/YYYY")}
                </Typography>
              )}
            </div>
            <div className="reconciliation-payable-row-record-name-container">
              <Typography variant="caption">
                Reference: {localPayable.reference}
              </Typography>
              <Typography variant="caption">
                Total: {currencyFormat(localPayable.total)}
              </Typography>
              <Typography variant="caption">
                Subtotal: {currencyFormat(localPayable.subtotal)}
              </Typography>
            </div>
          </div>

          <div className="reconciliation-payable-row-icon-container">
            <ArrowDropDownIcon />
          </div>
        </div>
        {isExpanded && (
          <div className="reconciliation-payable-row-body-container">
            {(localPayable.recordType === "MEDIA" ||
              localPayable.recordType === "UNRECOGNISED_MEDIA") && (
              <PayableMediaForm
                payable={localPayable}
                mediaSuppliers={mediaSuppliers}
                handleUpdateLocalPayable={handleUpdateLocalPayable}
                companies={companies}
              />
            )}
            {localPayable.recordType === "RECEIPT" && (
              <ReceiptForm
                payable={localPayable}
                handleUpdateLocalPayable={handleUpdateLocalPayable}
                handleUpdateMultiplePropertiesLocalPayable={
                  handleUpdateMultiplePropertiesLocalPayable
                }
                companies={companies}
                staff={staff}
                projects={projects}
              />
            )}
            {localPayable.recordType === "PO" && (
              <ApInvoiceForm
                payable={localPayable}
                handleUpdateLocalPayable={handleUpdateLocalPayable}
                companies={companies}
              />
            )}

            <div className="reconciliation-save-button-container">
              <div className="reconciliation-align-button-container">
                <div className={"reconciliation-align-button-container-start"}>
                  <Button
                    onClick={displayRejectWarning}
                    className={`default-button ${localPayable.recordType == "RECEIPT" ? "reconciliation-reject-receipt-button" : ""}`}
                  >
                    {`${
                      localPayable.recordType == "RECEIPT" ? "REJECT" : "DELETE"
                    }`}
                  </Button>

                  {localPayable.recordType === "MEDIA" &&
                    user?.role == "Super User" && (
                      <Button
                        onClick={handleOpenSendBillToXero}
                        className={
                          !payable.reference
                            ? "default-disabled-button"
                            : "default-button"
                        }
                        disabled={!payable.reference}
                      >
                        SEND BILL TO XERO
                      </Button>
                    )}
                  {localPayable.recordType === "MEDIA" && (
                    <Button
                      onClick={() => setOpenSplitPayableDialog(true)}
                      className="default-button"
                    >
                      SPLIT BILL
                    </Button>
                  )}
                  {(localPayable.recordType === "MEDIA" || localPayable.recordType === "PO") && (
                    <Button
                      onClick={() => {
                        setOpenConvertDialog(true);
                        setConvertToType(localPayable.recordType === "MEDIA" ? "PO" : "MEDIA");
                      }}
                      className="default-button"
                    >
                      {`CONVERT TO ${localPayable.recordType === "MEDIA" ? "PO" : "MEDIA"}`}
                    </Button>
                  )}
                </div>
                {(localPayable.recordType === "MEDIA" ||
                  localPayable.recordType === "RECEIPT") && (
                  <Button
                    onClick={handleApproveForPayment}
                    className={
                      localPayable.status !== "Pending"
                        ? "default-button-grey"
                        : "default-button-green"
                    }
                    disabled={localPayable.status !== "Pending"}
                  >
                    {localPayable.status === "Pending"
                      ? "Click to Approve"
                      : `${
                          localPayable.recordType === "MEDIA"
                            ? "Approved by Media Team"
                            : "Approved for Payment"
                        }`}
                  </Button>
                )}

                {/* {localPayable.recordType === "MEDIA" && (
                  <Button
                    onClick={handleOpenNotifyMediaTeam}
                    className="default-button-red"
                  >
                    NOTIFY MEDIA TEAM
                  </Button>
                )} */}
              </div>

              {hasChanges && (
                <Button onClick={handleSavePayable} className="default-button">
                  SAVE
                </Button>
              )}
            </div>

            <PayableFileViewer
              payable={localPayable}
              loadingPdf={loadingPdf}
              setLoadingPdf={setLoadingPdf}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default PayableRow;
