import React, { createContext, useContext, useEffect, useState } from "react";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
  sendPasswordResetEmail,
  fetchSignInMethodsForEmail,
  signInWithPhoneNumber,
  linkWithPhoneNumber,
  signInWithPopup,
} from "firebase/auth";
import { auth } from "../../config/firebase";
import axios from "axios";

export const AuthContext = createContext({ user: {} });

export const AuthContextProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(null);
  const [isLoadingUser, setIsLoadingUser] = useState(true);

  useEffect(() => {
    const checkAuth = async () => {
      setIsLoadingUser(true);
      axios
        .get("auth/getAuthenticatedUser")
        .then(({ data }) => {
          setUser(data);
          setIsAuthenticated(true);
        })
        .catch((err) => {
          setUser(null);
          setIsAuthenticated(false);
        })
        .finally(() => {
          setIsLoadingUser(false);
        });
    };

    checkAuth();
  }, []);

  const hasAdminAccess = () => {
    return user?.role === "Admin" || user?.role === "Super User";
  };

  const hasMediaAccess = () => {
    return user?.department === "Media Team";
  };

  return (
    <AuthContext.Provider
      value={{ isAuthenticated, user, isLoadingUser, hasAdminAccess, hasMediaAccess }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth() {
  return useContext(AuthContext);
}
